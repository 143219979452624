import React from 'react';
import { useSelector } from 'react-redux';

import Section from '@components/common/Section';
import HomepageProductsSlider from '@components/pages/home/products-slider/HomepageProductsSlider';
import { IHomePageComponentDataObject } from '@interfaces/home';
import { IStore } from '@interfaces/store';

interface ISectionProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const HomePageProductsSlider: React.FunctionComponent<ISectionProps> = ({ component, className }) => {
  const homePageProductsLength = useSelector((state: IStore) => state.common.homePageProducts?.length);
  const loaded = useSelector((state: IStore) => state.common.loaded.homePageProducts);
  const isItemsExist = loaded && homePageProductsLength > 0;

  return isItemsExist ? (
      <Section className={className} title={component?.heading || ''}
               showGenderCategories={false}
               showViewAllButton={false}
      >
        <HomepageProductsSlider component={component} />
      </Section>
  ) : null;
};

export default HomePageProductsSlider;
